<template>
    <div class="container-activation">
        <section class="wrapper-activacion">

            <div class="wrapper-logo">
                <img class="logo-app-login"
                    src="../assets/images/logo-dembora-horiz.svg"
                    alt="Logo product">
            </div>

            <div v-if="isPendingToActivate">
                <p>{{ $t('activation.welcome') }}</p>
                    <form @submit.prevent="onSubmit">

                        <div class="mdl-textfield mdl-js-textfield">
                            <label class="activation-label" for="password">{{ $t('activation.password-placeholder') }}</label>
                            <input class="mdl-textfield__input activation-input" type="password" v-model="password" id="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                        </div>

                        <div class="mdl-textfield mdl-js-textfield">
                            <label class="activation-label" for="verify-password">{{ $t('activation.password-verify-placeholder') }}</label>
                            <input class="mdl-textfield__input activation-input" type="password" v-model="passwordVerification" id="verify-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                        </div>

                        <div class="wrapper-checkbox-legal">
                            <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect checkbox-legal"
                                for="checkbox-legal">
                                <input type="checkbox" id="checkbox-legal" v-model="checkedLegal" class="mdl-checkbox__input" required>

                                <span class="label-legal">
                                    {{ $t('activation.legal-employee')}}
                                </span>

                            </label>

                            <span class="label-legal-link" @click="showModal">
                                {{ $t('activation.legal-employee-modal')}}
                            </span>
                        </div>

                        <div class="wrapper-submit">
                            <button type="submit" value="Activate" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
                                {{ $t('activation.activate') }}
                            </button>
                        </div>

                        <modal-template v-show="isModalVisible"
                                    @close="closeModal">

                            <template v-slot:header>
                                {{ $t('activation.modal-title')}}

                                <button type="button"
                                        class="btn-blue"
                                        @click="closeModal"
                                        aria-label="Close modal"
                                >X</button>

                            </template>

                            <template v-slot:body>
                                <p class="legal-text">{{ $t('activation.modal-legal-p1')}}</p>
                                <p class="legal-text">{{ $t('activation.modal-legal-p2')}}</p>
                                <p class="legal-text">{{ $t('activation.modal-legal-p3')}}</p>
                                <p class="legal-text">{{ $t('activation.modal-legal-p4')}}</p>
                                <p class="legal-text">{{ $t('activation.modal-legal-p5')}}</p>
                                <p class="legal-text">{{ $t('activation.modal-legal-p6')}}</p>
                            </template>

                            <template v-slot:footer>
                                <button type="button"
                                        class="btn-blue"
                                        @click="closeModal"
                                        aria-label="Close modal"
                                >
                                    {{ $t('activation.modal-close-button')}}
                                </button>
                            </template>
                        </modal-template>

                    </form>
            </div>

            <div v-else>
                <p>{{ $t('activation.employee-activated', {email: this.email}) }}</p>
            </div>
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
        </section>
    </div>
</template>

<style scope lang="scss">
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal {
        background: #fff;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 335px;
        height: 203px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #000;
        font-size: 16px;
        justify-content: space-between;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;

        .legal-text {
            font-size: 12px;
            line-height: normal;
        }
    }

    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #2196f3;
        background: transparent;
    }

    .btn-blue {
        color: white;
        background: #2196f3;
        border: 1px solid #2196f3;
        border-radius: 2px;
        cursor: pointer;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
</style>

<script>
    import {mapState, mapActions} from 'vuex'
    import VueElementLoading from 'vue-element-loading'
    import ModalTemplate from '@/components/shared/ModalTemplate.vue'

    export default {
        name: "EmployeeActivation",
        data() {
            return {
                isPendingToActivate: true,
                token: '',
                email: '',
                password: '',
                passwordVerification: '',
                checkedLegal: false,
                isModalVisible: false
            }
        },
        created() {
            this.token = this.$route.params.token
            this.email = this.$route.params.email
        },
        computed: {
            ...mapState({
                show: state => state.auth.requesting
            })
        },
        methods: {
            ...mapActions('auth', ['activateEmployee']),
            async onSubmit() {
                if (this.password !== this.passwordVerification) {
                    this.$toast.error(this.$i18n.t('shared.password-mismatch'))
                    return
                }

                if (this.checkedLegal === false) {
                    this.$toast.error(this.$i18n.t('activation.error-no-legal'))
                    return
                }

                try {
                    await this.activateEmployee({
                        email   : this.email,
                        token   : this.token,
                        password: this.password
                    })
                    this.isPendingToActivate = false
                } catch(error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            }
        },
        components: {
            VueElementLoading,
            ModalTemplate
        }
    }
</script>
