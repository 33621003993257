<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header"></slot>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body"></slot>
        </section>
        
        <footer class="modal-footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style scope lang="scss">
</style>

<script>
  export default {
    name: 'ModalTemplate'
  };
</script>
